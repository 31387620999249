namespace('migoa.components.aso');

migoa.components.aso.List = Class.create(migoa.components.aso.Search, {
	
	/* constructor */
	initialize: function($super,datesType) {
		$super(datesType);
		var me = this;
	},
	
	_beforeSearch: function($super,asoParams) {
		asoParams = $super(asoParams);
		var me = this;
		
		if (asoParams) {
			var hasDates = asoParams.date != null;
			var searchString = asoParams.s;
			var actionType = (hasDates) ? 'LIST-PRICE-REQUEST' : 'LIST-REQUEST';
			MIGOA.analytics.trackEvent('Serp-Search', actionType, searchString);
		}
		return asoParams;
	},
	
	_afterSearch: function($super,data) {
		$super(data);
		var me = this;
	
		var hasDates = me.hasDates();
		
		var count = (data.total_ads) ? data.total_ads : 0;
		var searchString = data.location_string;		
		var actionType = (hasDates) ? 'LIST-PRICE-RESPONSE' : 'LIST-RESPONSE';
		MIGOA.analytics.trackEvent('Serp-Search', actionType, searchString, count);
			
		// Set destination input
		MIGOA.page.components['destinationInputComp'].el.val(data.location_string);
		MIGOA.page.components['destinationInputComp'].elHint.val(data.hint);
			
		var url = MIGOA.ui.toLink(data.url);
		MIGOA.history.push({
			pathname: url.pathname,
			search: url.search,
			state: { some: 'state' }
		});		    			
			
//		if (hasDates && data.total_ads < 15 && MIGOA.page.center) { 
//			$("#more-ads-container").html('');  
//			me.searchMoreAds();
//		}
	}
	
//	searchMoreAds: function() {
//		var me = this;
//		
//		var lat = MIGOA.page.center.lat;
//		var lng = MIGOA.page.center.lng;
//		var excludeIds = Object.keys(me.mapMarkers);
//
//		var dateFrom = me.getFromDate();
//		var dateTo = me.getToDate();
//		
//		if (me._validDateRange(dateFrom, dateTo)) {
//		
//			var asoParams = {
//				date:  MIGOA.ui.dateAsString(dateFrom),
//				start_date:  MIGOA.ui.dateAsString(dateFrom),
//				end_date: MIGOA.ui.dateAsString(dateTo),
//				paxes: me.paxesAsString(),
//				length: me.getLength(dateFrom,dateTo),
//				latitude: lat,
//				longitude: lng,
//				excludeIds : excludeIds
//			};
//			
//			asoParams = me._cleanAsoParams(asoParams);
//
//			jQuery('#more-ads-loading-container').show();
//		
//			$.ajax({
//				url: MIGOA.page.languagePrefix + '/_ajax/show_more_ads',
//				data: asoParams
//			})
//			.done(function(data) {
//			
//				let container = jQuery('#more-ads-container');
//				container.html(data);
//				MIGOA.page.initImageCarousel(container);
//		
//				MIGOA.ui.updateLinksParams(me, '#more-ads-container');
//			})
//			.always(function(){
//				jQuery('#more-ads-loading-container').hide();
//			});
//		}
//	}
	
});