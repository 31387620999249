namespace('migoa.components.modal');

migoa.components.modal.SerpMap = Class.create(migoa.components.Modals, {

	latitude: null,
	longitude: null,
	detailsMap: null,
	
	/* constructor */
	initialize : function($super, id, options) {
		var me = this;
		
		// Merge specific options overrides defaults
		$.extend(true, me, options);
		
		$super(id, options);
	},
	
	afterLoad: function($super) {
		$super();
		var me = this;
		$('#serp-map').empty();
		setTimeout(function() {
			me.initGmaps(me.latitude, me.longitude);
		},500);			
	},
	
	initGmaps: function(lat, lon) {
		var me = this;
		me.detailsMap = new migoa.components.Gmaps('serp-map',  {
			scrollwheel: false,
			center: new google.maps.LatLng(lat, lon),
			fullscreenControl: false,
			mapTypeControl: true,
			mapTypeControlOptions: {
		        position: google.maps.ControlPosition.RIGHT_TOP
  			},
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_CENTER
			}
		});

		var center = new google.maps.LatLng(lat, lon);
		me.markers = {}; 
		
		let markerOptions = { position: { lat: lat, lng: lon }, type: 'DEFAULT-NO-PRICE' };
		me.markers[lat] = new OAMarker(markerOptions);
		
		me.detailsMap.updateMarkers(me.markers);
		me.detailsMap.map.setCenter(center);
		me.detailsMap.map.setMapTypeId(me.detailsMap.map.mapTypeId);
		me.detailsMap.resize(true);
	}

});