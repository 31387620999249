namespace('migoa.pages');

migoa.pages.List = Class.create(migoa.pages.Search, {

	/* constructor */
	initialize: function($super, isBasePathBased, productSegment, segmentPrefix) {
		$super(isBasePathBased, productSegment, segmentPrefix);
	},	
	
	/* Override */
	onDocumentLoaded: function($super) {
		$super();
		var me = this;		
		
		// Create aso with common options
		me.initAso();

		me.toggleDropDowns();
		
		me.initImageCarousel(me.components['resultsContainer']);
//		me.initImageCarousel($('#more-ads-container'));
		
		// Update links with search parameters
	    MIGOA.ui.updateLinksParams(me.aso);
	},
	
	initAso: function() {
		var me = this;

		me.aso = new migoa.components.aso.List(me.productSegment == 'ACTIVITY' ? 'd' : 'n');
		
		me.aso.onStartSearch = function(asoParams) {};

		me.aso.onEndSearch = function(data) {
			me.initPageSearchComponentsEveryAJAXResponse();
			me.initImageCarousel(me.components['resultsContainer']);
			me.initTooltips();
		};
	},
	
	/* Extend */
	initModals: function($super){
		$super();
		var me = this;
		
       	// Init full screen loader modal
       	me.modals['full-screen-loader'] = new migoa.components.Modals('full-screen-loader', {addToHistory: false});
       	
       	// Init serp-map modal
		$(document).on('click', '[data-modal=serp_map]', function() {
			var lat = $(this).data('lat');
			var lon = $(this).data('lon');
			me.modals['serp_map'] = new migoa.components.modal.SerpMap('serp_map', {
				latitude: lat,
				longitude: lon,
				openOnInit: true
			});
		});
	},
	
	hideSpinner: function() {
		var me = this;
		me.modals['full-screen-loader'].close();
	},
	
	showSpinner: function() {
		var me = this;
		me.modals['full-screen-loader'].open();		
	}

});
