namespace('migoa.components.aso');

migoa.components.aso.Search = Class.create(migoa.components.Aso, {

	page: 1,
	resetPage: true,
	isMap: false,
	mapMarkers: null,
	
	
	/* constructor */
	initialize: function($super,datesType) {
		$super(datesType);
		var me = this;
		
		// Update number of filters applied
		me.updateFiltersCounter();
		
		// Initialize pager component
		me._initPagination();
	},
	
	buildAsoParams: function($super) {
		var asoParams = $super();
		var me = this;

		if (me.resetPage) {
			me.page = 1;
		}
		
		var searchString = me.getSearchString();
		var hint = me.getHint();
		var orderby = me.getOrderBy();

		var amenities = me.getAmenities();
		var stars = me.getStars();
		var boards = me.getBoards();
		var productTypes = me.getProductTypes();
		var collections = me.getCollections();
		var tags = me.getTags();
		var districts = me.getDistricts();
		var priceRange = me.getPriceRange();
		
		var moreParams = {
			s: searchString,
			hint: hint,
			order: orderby ? orderby.order : null,
			way: orderby ? orderby.way : null,
			page: me.page == 1 ? null : me.page,
			amenities: amenities ? amenities.join(',') : null,
			stars: stars ? stars.join(',') : null,
			collections: collections ? collections.join(',') : null,
			tags: tags ? tags.join(',') : null,
			boards: boards ? boards.join(',') : null,
			product_types: productTypes ? productTypes.join(',') : null,
			districts: districts ? districts.join(',') : null,
			pr: priceRange
		};

		$.extend(asoParams, moreParams);
		
		asoParams = me._cleanAsoParams(asoParams);
		
		return asoParams;
	},
	
	getSearchString: function(){
		var me = this;
		
		if (typeof MIGOA.page.components['destinationInputComp'] === 'undefined') return null;
		
		return MIGOA.page.components['destinationInputComp'].el.val();
	},

	getHint: function(){
		var me = this;
		
		if (typeof MIGOA.page.components['destinationInputComp'] === 'undefined') return null;
		
		return MIGOA.page.components['destinationInputComp'].elHint.val();
	},
	
	getOrderBy: function(){
		var me = this;
					
		var selected = $('td.active', '.section-sortable .aso-sort');
		if (!selected.attr('data-order')) { // is mobile, not on <td>
			selected = $('.sort-mobile option:selected');  // let's get the mobile option selected from <select>
		}	 
								
		if (selected != null) {
			return {
				order: selected.attr('data-order'),
				way: selected.attr('data-way')
			};
		} else {
			return null;
		}
	},

	getAmenities: function(){
		var me = this;				
		
		if (typeof MIGOA.page.components['amenities'] === 'undefined') return null;
		
		var amenities = [];
		
		MIGOA.page.components['amenities'].filter(function(){
			if(this.checked){
				amenities.push(this.value);				
			}
		});
		
		return amenities;
	},

	getStars: function(){
		var me = this;				
		
		if (typeof MIGOA.page.components['stars'] === 'undefined') return null;
		
		var stars = [];
		
		MIGOA.page.components['stars'].filter(function(){
			if(this.checked){
				stars.push(this.value);				
			}
		});
		
		return stars;
	},

	getProductTypes: function(){
		var me = this;				
		
		if (typeof MIGOA.page.components['productTypes'] === 'undefined') return null;

		var productTypes = [];
		
		MIGOA.page.components['productTypes'].filter(function(){
			if(this.checked){
				productTypes.push(this.value);				
			}
		});
		
		return productTypes;
	},

	getTags: function(){
		var me = this;				
		
		if (typeof MIGOA.page.components['tags'] === 'undefined') return null;

		var tags = [];
		
		MIGOA.page.components['tags'].filter(function(){
			if(this.checked){
				tags.push(this.value);				
			}
		});
		
		return tags;
	},

	getCollections: function(){
		var me = this;				
		
		if (typeof MIGOA.page.components['collections'] === 'undefined') return null;

		var collections = [];
		
		MIGOA.page.components['collections'].filter(function(){
			if(this.checked){
				collections.push(this.value);				
			}
		});
		
		return collections;
	},
	
	getBoards: function(){
		var me = this;				
		
		if (typeof MIGOA.page.components['boards'] === 'undefined') return null;

		var boards = [];
		
		MIGOA.page.components['boards'].filter(function(){
			if(this.checked){
				boards.push(this.value);				
			}
		});
		
		return boards;
	},
	
	getDistricts: function(){
		var me = this;				
		
		if (typeof MIGOA.page.components['districts'] === 'undefined') return null;

		var districts = [];
		
		MIGOA.page.components['districts'].filter(function(){
			if(this.checked){
				districts.push(this.value);		
			}
		});
		
		return districts;
	},
	
	getPriceRange: function(){
		var me = this;
		
		var dropDown = MIGOA.page.dropdowns['dropPrice'];
		if (dropDown != null) {
			// Is a dropdown (responsive version)
			return dropDown.getSelectedValue();
		}
		
		var priceRanges = [];
		
		var checkboxList = MIGOA.page.components['priceRange']
		if (checkboxList != null) {	
			
			// Is a checkbox list (mobile version)
			checkboxList.filter(function(){
				if (this.checked) {
					priceRanges.push($(this).closest('li').attr('data-value'));		
				}
			});
		}

		if (priceRanges.length > 0) {
			return priceRanges.join(',');
		}
		
		return null;
	},
	
	setPage: function(page) {
		var me = this;
		me.page = page;
	},
	
	_beforeSearch: function(asoParams) {
		var me = this;					
		
		// destination is required	
 		if (asoParams['s'] == null || asoParams['s'] == "") {
 			MIGOA.page.components['destinationInputComp'].el.focus();
 			return false;
 		}

		var destinationChanged = MIGOA.page.components['destinationInputComp'].hasChanged();
		if (destinationChanged) {
		
			// if Destination has changed, redirect to a full new url (list or map)
			var url = MIGOA.page.languagePrefix + '/_ajax/urlformcreator';
			if (this.isMap) {
				url += '?map=1';
			}
		
			// make sure we are not keeping the map parameters...
			delete asoParams['bbox.topLeft'];
			delete asoParams['bbox.bottomRight'];
			delete asoParams['bbox.zoom'];
			delete asoParams['geoname_id'];
			delete asoParams['distance'];
		
			// post to urlformcreator and redirect to received url...
			$.ajax({
				url: url,
				data: asoParams,
				method: "POST"
			})
			.done(function(data){
				window.location.replace(data);
			});
			
			return false;
			
		} else {
			return asoParams;
		}
	},
	
	_afterSearch: function(data) {
		var me = this;
		
		if (data.data_layer) { eval(data.data_layer); }	

		if (data.total_ads_found_title) { MIGOA.page.components['totalAdsFoundTitle'].html(data.total_ads_found_title); }

		if (data.results) { MIGOA.page.components['resultsContainer'].replaceWith(data.results); }
		
		if (data.filters) { MIGOA.page.components['filtersContainer'].replaceWith(data.filters); }
		
		if (data.breadcrums) { MIGOA.page.components['breadcrumbs'].replaceWith(data.breadcrums); }
			
		if (data.ad_locations) { me.setAdsMapInfo(JSON.parse(data.ad_locations)); }

		if (data.dateStartShortFormat && data.dateStartEndFormat) { 
			if (data.dateStartShortFormat == data.dateStartEndFormat) {
				$(".search-dates .dest-dates").html(data.dateStartShortFormat);	
			} else {
				$(".search-dates .dest-dates").html(data.dateStartShortFormat + ' - ' +data.dateStartEndFormat);	
			}
			$(".search-dates .dates").show();
			$(".search-dates .text").hide();
		} else {
			$(".search-dates .dates").hide();
			$(".search-dates .text").show();
		}

//		if (data.more_ads) {
//			$("#more-ads-container").html(data.more_ads);
//		} else {
//			$("#more-ads-container").html('');
//		}
		
		if (data.url_list) {
			$('#map-list-switch').attr("data-href",data.url_list);
		} else if (data.url_map) {
			$('#map-list-switch').attr("data-href",data.url_map);
		}

		// Init new rendered results 
		me._initPagination();		
		
		// Update number of filters applied
		me.updateFiltersCounter();

		// Scroll top when AJAX
		if($('#result_container').length > 0){
			if ($(window).scrollTop() > $('#result_container').offset().top - 60) {
				window.scrollTo(0, $('#result_container').offset().top - 60);
			}
		}
		
		// Add aso params to page links
		MIGOA.ui.updateLinksParams(me);
	},
	
	_initPagination: function() {
		var me = this;
		
		// Init pager
		me.pagerComp = $('#results-paginator');
//		let page = me.getUrlParameter('page');
//		if (page) {
//			me.setPage(page);
//		}

		me.pagerComp.on('click touchend', 'button', function(e) {
			e.preventDefault();
			var page = $(this).attr('data-page');	
			me.changePage(page);
			
			return false;
		});
	},
	
	getUrlParameter: function(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	},

	updateFiltersCounter: function() {
		var me = this;
		
		var productTypes = me.getProductTypes();
		var amenities = me.getAmenities();
		var stars = me.getStars();
		var collections = me.getCollections();
		var tags = me.getTags();
		var boards = me.getBoards();
		var districts = me.getDistricts();
		
		var totalFiltersCounter = amenities.length + districts.length + productTypes.length + boards.length + stars.length + collections.length + tags.length;
		
		totalFiltersCounter > 0 ? $('#filters-counter').text(' (' + totalFiltersCounter + ')').show() : $('#filters-counter').hide();
	},
	
	setAdsMapInfo: function(mapMarkers) {
		var me = this;	
		me.mapMarkers = mapMarkers;
	},	
		
	changePage: function(page){
		var me = this;
		
		$('html body').scrollTop(170);
		
		me.resetPage = false;
		me.page = page;
		me.search();
		me.resetPage = true;
	},
  
	searchByFilter: function(){
		var me = this;
		me.search();
	},  
  
	searchByPrice: function() {
		var me = this;
		me.search();
	},
  
	searchByOrder: function() {
		var me = this;
		me.search();
	}
	
});