namespace('migoa.components.dropdown');

migoa.components.dropdown.Price = Class.create(migoa.components.Dropdown, {

	selectedOrdered : null,
	
	initialize : function($super, id, callMethod) {
		$super(id, callMethod);
		var me = this;
		this.selectedOrdered=[];
		for(var i=0;i<this.selected.length;i++) {
			this.selectedOrdered.push({k: $(this.selected[i]).attr('data-value'), v: $(this.selected[i])});
		}
		me.selectedValue = "";
		for(var i=0;i<me.selectedOrdered.length;i++) {
			me.selectedValue+=me.selectedOrdered[i].k+",";
		}
		if(me.selectedValue != "") {
			me.selectedValue=me.selectedValue.substr(0,me.selectedValue.length-1);
		}
	},
	
	selectMultiOption: function(e){
		var me = this;
		
		var currentSelection = $(e.delegateTarget);
		var currentKey = currentSelection.data('value');

		// Has been previously selected?
		var index = MIGOA.ui.indexOfObjectByKeyValue(me.selectedOrdered, 'k', currentKey);
		if (index !== -1) {
			
			// Delete selected option from array of selected		
			me.selectedOrdered.splice(index, 1);
			currentSelection.removeClass('active');

			
		}else{
			
			me.selectedOrdered.push({k: currentKey, v: currentSelection});
			MIGOA.ui.orderArrayByKey(me.selectedOrdered, 'k');
			currentSelection.addClass('active');
			
			// More than 1 item selected...
			if(me.selectedOrdered.length > 1){
				
				var firstSelected = me.selectedOrdered[0].k;
				var lastSelected = me.selectedOrdered[me.selectedOrdered.length-1].k;
				
				// Each option between (force select)
				var autoSelectedOptions = $.grep(me.options, function(option){					
					var rangeVal = $(option).data('value');										
					return !$(option).hasClass('active') && rangeVal > firstSelected && rangeVal < lastSelected;  					
				});				
				
				$.each(autoSelectedOptions, function(){
					me.selectedOrdered.push({k: $(this).data('value'), v: $(this)});
					MIGOA.ui.orderArrayByKey(me.selectedOrdered, 'k');
					$(this).addClass('active').children('input').prop('checked', true);
				});
				
			}
						
		}

		// Rendering text for multioption dropdown
		var text = '';
		
		// Case more than one option selected
		if (me.selectedOrdered.length > 0){
			
			if (me.selectedOrdered.length == 1){
				
				text = me.selectedOrdered[0].v.data('text');
				
			} else {
				
				// Range all selected
				if (me.selectedOrdered.length == me.options.length){
					text = me.defaultText;
				
				// Any but not all and last not selected
				} else if (me.selectedOrdered[me.selectedOrdered.length-1].k != me.options.last().data('value')) {
	
					// First range selected
					if (me.selectedOrdered[0].k == me.options.first().data('value')){
						text = MIGOA.page.getTranslation('below') + ' ' + me.selectedOrdered[me.selectedOrdered.length-1].v.data('max');
					} else {
						text = me.selectedOrdered[0].v.data('min') + ' - ' + me.selectedOrdered[me.selectedOrdered.length-1].v.data('max');
					}
				
				// Last range selected
				} else {
					text = MIGOA.page.getTranslation('above') + ' ' + me.selectedOrdered[0].v.data('min');
				}
			}
			me.selectedValue = "";
			for(var i=0;i<me.selectedOrdered.length;i++) {
				me.selectedValue+=me.selectedOrdered[i].k+",";
			}
			if(me.selectedValue != "") {
				me.selectedValue=me.selectedValue.substr(0,me.selectedValue.length-1);
			}
									
		} else {
			text = me.defaultText;
			me.selectedValue = "";
		}
		
		// Set visible text
		me.text.text(text);
		me.selected = me.options.filter('.active');
		me.afterSetOption(me.selected);
	}
});