namespace('migoa.components.dropdown');

migoa.components.dropdown.SortByDropdown = Class.create(migoa.components.Dropdown, {

	wayInput: null,

	/* constructor */
	initialize : function($super, id, settings) { 
		$super(id, settings);
		var me = this;

		me.wayInput = $('input[name=way]');
	},

	/* override */
	_updateInput: function($super) {
		var me = this;
		
		// Setting input > has data-value? else text
		me.selectedValue = me.selected.data('value');
		var values = me.selectedValue.split(',');
		
		me.input.val(values[0]);
		me.wayInput.val(values[1]);
	}

});