namespace('migoa.pages');

migoa.pages.Search = Class.create(migoa.pages.Generic, {

	aso: null,
	
	/* constructor */
	initialize: function($super, isBasePathBased, productSegment, segmentPrefix) {
		$super(isBasePathBased, productSegment, segmentPrefix);
	},	
	
	/* Override */
	onDocumentLoaded: function($super) {
		$super();
		var me = this;
		
		me.initPageSearchComponents();
		me.initPageSearchComponentsEveryAJAXResponse();
		
		// Sort element init
		me.initSort();

		// switch list/map and navigate to 
		$(document).on('change', '#map-list-switch', function() {
			me.navigateTo($(this));
		});
	},
	
	initPageSearchComponents: function() {
		var me = this;
		
		// Destination Input
		me.components['destinationInputComp'] = new migoa.components.Autocomplete('#fdestinationName', false);   	
		me.components['destinationInputComp'].onSend = function() {
			me.submitSearch();
		};
		
		// Datepicker
		me.components['datepicker'] = new migoa.components.DatePicker('#fcalformat-arr', '#fcalformat-go');
		me.components['datepicker'].afterSelectDates = function(){
			me.toggleDropDowns();
		};
		me.components['datepicker'].afterClearDates = function(){
			me.toggleDropDowns();
			me.dropdowns.dropPrice.resetDefault();
		};
		
		
		// Paxes dropdowns
		me.dropdowns['dropAdults'] = new migoa.components.Dropdown('drop-adults');
		me.dropdowns['dropChildren'] = new migoa.components.Dropdown('drop-children');
		me.dropdowns['dropChildrenAges'] = new migoa.components.dropdown.Ages('drop-children-ages');
		me.dropdowns['dropChildren'].afterSetOption = function($selected, value) {
			me.dropdowns['dropChildrenAges'].changeNumberValue(value);
		};

		me.components['breadcrumbs'] = $(".breadcrumb-element");
		me.components['totalAdsFoundTitle'] = $("#total-ads-found-title");
	},
	
	initPageSearchComponentsEveryAJAXResponse: function(){
		var me = this;

		me.components['resultsContainer'] = $("#result_container");
		me.components['filtersContainer'] = $('#container_filters');
		me.initCollapseFilters();
		
		me.components['productTypes'] = $('#collapse-filters [data-filter-group=product_types] [data-filter=product_type]');
		me.components['amenities'] = $('#filters-amenities [data-filter=facilities]');
		me.components['stars'] = $('#collapse-filters [data-filter-group=stars] [data-filter=stars]');
		me.components['collections'] = $('#collapse-filters [data-filter-group=collections] [data-filter=collection]');
		me.components['tags'] = $('#collapse-filters [data-filter-group=tags] [data-filter=tag]');
		me.components['boards'] = $('#collapse-filters [data-filter-group=boards] [data-filter=boards]');
		me.components['districts'] = $('#collapse-filters [data-filter-group=districts] [data-filter=district]');
		
		me.dropdowns['dropPrice'] = new migoa.components.dropdown.Price('drop-price');		
		me.dropdowns['dropPrice'].afterSetOption = function(){
			me.submitSearch();
		};
		
		me.components['primaryFilters'] = $('#primary_amenities_filters input[data-filter=facilities]'); 
		me.components['primaryFilters'].on('change', function(){
			
			// Set equivalent on amenities list
			var id = $(this).data('id');
			var amenity = me.components['amenities'].filter('#' + id + '_amenity');
			amenity.prop('checked', $(this).prop('checked'));
			
			me.submitSearch();
		});			
		
		// Show calendar and scroll if no dates
		$(document).on('click', '.view-availability', function(){
			if (me.aso.hasDates()) {
				me.submitSearch();
			} else {
				$('html, body').animate({
					scrollTop: 0,
				}, 500, function(){
					me.components['datepicker'].fromInput.datepicker('show');
				});
			}
		});
	},
	
	submitSearch: function() {
		var me = this;
		me.aso.search();			
	},
	
	toggleDropDowns: function() {
		var me = this;
		
    	if (me.aso.hasDates()) {
    		me.dropdowns['dropPrice'].enable();
				
    		var $tooltip = me.dropdowns['dropPrice'].element.parent();
			$tooltip.tooltip("destroy");
			
			// Table sort events ENABLE_SORTABLE_ACTION
			$('.table-sortable').find('[data-order=3]').removeAttr('disabled');
    		
    	} else {
    		me.dropdowns['dropPrice'].disable();
    		
    		var $tooltip = me.dropdowns['dropPrice'].element.parent();
			var text = $tooltip.data('text');
			$tooltip.tooltip({
				title: text
			});
			
			// Table sort events ENABLE_SORTABLE_ACTION
			$('.table-sortable').find('[data-order=3]').attr('disabled', 'disabled');
    	}
	},
	
	initCollapseFilters: function () {
		var me = this;
		var moreFiltersId = '#collapse-filters';
		
		me.components['filtersContainer']
			.on('show.bs.collapse', moreFiltersId, function () {
				me.aso.updateFiltersCounter();
			})
			.on('hide.bs.collapse', moreFiltersId, function () {
				me.aso.updateFiltersCounter();
			});
		
		if (me.components['filtersContainer'].find('.more-filters').hasClass('in')) {
			me.components['filtersContainer'].find('.more-filters').trigger('show.bs.collapse');
		}
		
	},
	
	initSort: function() {
		var me = this;
		
		$('.table-sortable').on('click', 'td:not(.no-selectable)', function(){
			var $item = $(this);
			
			if ($item.is('[disabled]')) return;				
			
			
			if ($item.hasClass('active')) {
				if ($item.attr('data-multiple-way')) {
					var way = $item.attr('data-way');
					if (way == 1) {
						$item.attr('data-way', 2);							
						$item.find('.icon-navigation-up').removeClass('active');
						$item.find('.icon-navigation-down').addClass('active');
					} else {
						$item.attr('data-way', 1);
						$item.find('.icon-navigation-down').removeClass('active');
						$item.find('.icon-navigation-up').addClass('active');
					}
				} else {
					return;
				}
			} else {
				$item.siblings('.active').removeClass('active').find('.active').removeClass('active');					
				$item.addClass('active');
			}
			me.submitSearch();
			
			// Avoid css active-hover jump
			setTimeout(function(){
				$item.removeClass('pointer-events-none');
			}, 4000);
			
		});		
	},
	
	collapseFilters : function() {
		var me = this;
		me.components['filtersContainer'].find('#collapse-filters').collapse('hide');
	},
	
	applyFilters : function() {
		var me = this;
		me.collapseFilters();
		me.submitSearch();
	},

	deleteFilters : function() {
		var me = this;

		$('#collapse-filters .btn-radio.sel').removeClass('sel');
		$('#collapse-filters input[data-filter]:checked').removeAttr('checked');
		me.collapseFilters();
		me.submitSearch();				
	}

});
